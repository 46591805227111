<template>
  <div>
    <slot v-if="showLoading" name="loading">
      <content-placeholders-text :lines="lines" />
    </slot>
    <b-alert v-else-if="!hideError && error" variant="danger" show>
      {{ error }}
    </b-alert>
    <slot
      v-if="hideLoading || (!error && !loading)"
      v-bind="{ loading, error, data }"
    />
  </div>
</template>

<script>
export default {
  name: 'StateRenderer',
  props: {
    loading: {
      type: Boolean
    },
    error: {
      type: [String, Object],
      default: null
    },
    data: {
      type: [String, Array, Object, Number],
      default: null
    },
    lines: {
      type: Number,
      default: 5
    },
    hideLoading: {
      type: Boolean
    },
    hideError: {
      type: Boolean
    }
  },
  computed: {
    showLoading() {
      return !this.hideLoading && this.loading
    }
  }
}
</script>
