<template>
  <StateRenderer v-bind="checkout">
    <CheckoutDetails
      :checkout="checkout.data"
      :checkouts="checkouts"
      readonly
      hide-warnings
    />
  </StateRenderer>
</template>

<script>
import { mapActions } from 'vuex'
import services from '@/services'
import withAsyncAction from '@/mixins/withAsyncAction'

import StateRenderer from '@/components/StateRenderer'
import CheckoutDetails from '@/components/Checkouts/CheckoutDetails'

export default {
  name: 'CheckoutDetailsReport',
  components: {
    StateRenderer,
    CheckoutDetails
  },
  mixins: [
    withAsyncAction({
      key: 'checkout',
      fetcher: {
        methodName: 'fetchCheckoutReport',
        handler: services.checkouts.fetchCheckoutReport
      }
    })
  ],
  computed: {
    checkouts() {
      const { loading, error } = this.checkout
      const data = [this.checkout.data]

      return {
        loading,
        error,
        data
      }
    }
  },
  created() {
    const { id } = this.$route.params

    if (!id) {
      this.$router.push({
        name: 'ReportCheckouts'
      })
    }

    this.fetchCheckoutReport({ id })
  },
  methods: {
    ...mapActions('checkout', ['fetchCheckouts'])
  }
}
</script>
